/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-30",
    versionChannel: "nightly",
    buildDate: "2023-11-30T00:14:52.094Z",
    commitHash: "78c34c1be9b8e0089058f1b14ced2f5f9f34107b",
};
